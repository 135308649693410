import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import styles from './App.module.scss';

import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Accredited from './pages/Accredited/Accredited';
import NonAccredited from './pages/NonAccredited/NonAccredited';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import NavigationList from './components/Navigation/NavigationList';

const App = () => {
  return (
    <BrowserRouter>
      <>
      <NavigationList />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/metody-akredytowane" component={Accredited} />
          <Route path="/metody-nieakredytowane" component={NonAccredited} />
          <Route path="/kontakt" component={Contact} />
          <Route component={ErrorPage} />
        </Switch>
      </>
    </BrowserRouter>
  )
}

export default App