import React, { useEffect, useState }  from 'react';
import { TimelineMax, Power1 } from 'gsap';
import styles from './Offer.module.scss';

import image01 from '../../../assets/images/offer-02.jpg';
import image02 from '../../../assets/images/offer-03.jpg';

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRef, visible]
}

const Offer = () => {

  const [setRef, visible] = useOnScreen({threshold: 0.5})
  
  const [tlOffer] = useState(new TimelineMax());
  let showTitle = null;
  let showTxt = null;
  let showImg1 = null;
  let showImg2 = null;

    useEffect(() => {
      if(visible) {
         tlOffer
          .to(showTitle, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut})
          .to(showTxt, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
          .to(showImg1, 0.6, {x: 0, opacity: 1, ease: Power1.easeOut})
          .to(showImg2, 0.6, {x: 0, opacity: 1, ease: Power1.easeOut})
      }
      // eslint-disable-next-line
    }, [visible]);

  return (
    <div className={styles.wrapper} ref={setRef}>

      <article className={styles.boxText}>
        <h2 className={styles.h2} ref={h2 => showTitle = h2}>Gwarantujemy bezstronność, niezależność, ciągłe doskonalenie.</h2>
        <p className={styles.txt} ref={p => showTxt = p}>Wieloletnie doświadczenie w branży oraz wysoko wykwalifikowany personel sprawia, że jesteśmy w stanie zapewnić Państwu usługi na bardzo wysokim poziomie. W celu ciągłego doskonalenia uczestniczymy w badaniach biegłości, potwierdzających nasze kompetencje w zakresie oferowanych usług.</p>
      </article>

      <div className={styles.boxImg}>
        <div className={styles.leftImg}>
          <img className={styles.img01} src={image01} ref={img => showImg1 = img} alt="pracownicy laboratorium"/>
        </div>
        <div className={styles.rightImg}>
          <img className={styles.img02} src={image02} ref={img => showImg2 = img} alt="laboratorium badawcze"/>
        </div>
      </div>
      
    </div>
  )
}

export default Offer
