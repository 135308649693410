import React, { useEffect, useState } from 'react';
import { TimelineMax, Power1 } from 'gsap';
import styles from './Solutions.module.scss';

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRef, visible]
}

const Solutions = () => {

  const [setRef, visible] = useOnScreen({threshold: 0.5})
  
  const [tlSolutions] = useState(new TimelineMax());
  let showTitle = null;
  let showSection = null;
  let showTxt1 = null;
  let showTxt2 = null;
  let showTxt3 = null;
  let showTxt4 = null;

    useEffect(() => {
      if(visible) {
         tlSolutions
          .to(showSection, 0.6, {scaleX: 1, ease: Power1.easeOut}, .2)
          .to(showTitle, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut})
          .to(showTxt1, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut})
          .to(showTxt2, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
          .to(showTxt3, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
          .to(showTxt4, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
      }
      // eslint-disable-next-line
    }, [visible]);

  return (
    <div className={styles.wrapper} ref={setRef}>
      <div className={styles.mineBox} ref={div => showSection = div}>

      <h3 className={styles.titleH3} ref={h3 => showTitle = h3}>Laboratorium Ochrony Środowiska Pracy Maciej Jankowski dostarcza kompleksowych rozwiązań w dziedzinach</h3>

      <section className={styles.box}>

          <div className={styles.boxBLeft}>
            <p className={styles.boxTxt} ref={p => showTxt1 = p}>
              <i className="fab fa-jira" style={{color: '#00C8FF', fontSize: '0.8rem', marginRight: '5px'}}/> Badań dotyczących inżynierii środowiska: hałas w środowisku pracy, drgania, oświetlenie, mikroklimat.
            </p>
          
            <p className={styles.boxTxt} ref={p => showTxt2 = p}>
              <i className="fab fa-jira" style={{color: '#00C8FF', fontSize: '0.8rem', marginRight: '5px'}}/> Badań chemicznych powietrza.
            </p>
          </div>
       
          <div className={styles.boxBRight}>
            <p className={styles.boxTxt} ref={p => showTxt3 = p}>
              <i className="fab fa-jira" style={{color: '#00C8FF', fontSize: '0.8rem', marginRight: '5px'}}/> Badań stężenia pyłów przemysłowych na stanowiskach pracy.
            </p>
          
            <p className={styles.boxTxt} ref={p => showTxt4 = p}>
              <i className="fab fa-jira" style={{color: '#00C8FF', fontSize: '0.8rem', marginRight: '5px'}}/> Pobierania próbek powietrza
            </p>
          </div>

      </section>

      </div>
    </div>
  )
}

export default Solutions
