const methodsAccredited = [
  {
    id: 1,
    title: "Pobieranie próbek w celu oceny narażenia zawodowego na:",
    types: [
      "pyły przemysłowe (frakcja wdychalna, frakcja respirabilna)",
      "substancje organiczne, w tym frakcja wdychana",
      "substancje nieorganiczne - frakcja respirabilna",
      "metale i ich związki, w tym (frakcja wdychalna, frakcja respirabilna)",
      "metoda dozymetrii indywidualnej",
      "metoda stacjonarna",
      "wskaźnik narażenia (z obliczeń)",
    ],
    code: "PN-Z-04008-7:2002+Az1:2004    ",
  },
  {
    id: 2,
    title:
      "Stężenie pyłowych czynników szkodliwych dla zdrowia – frakcja wdychalna",
    types: ["zakres: (0,2 – 20,0) mg/m3", "metoda grawimetryczna"],
    code: "PN-Z-04507:2022-05 PN-Z-04507:2022-05/Ap1:2022-08 ",
  },
  {
    id: 3,
    title:
      "Stężenie pyłowych czynników szkodliwych dla zdrowia – frakcja respirabilna",
    types: ["zakres: (0,2 – 20,0 mg/m3", "metoda grawimetryczna"],
    code: "PN-Z-04508:2022-05 PN-Z-04508:2022-05/Ap1:2022-08",
  },
  {
    id: 4,
    title: "Stężenie gazów, zakres:",
    types: [
      "CO: (3,5 – 585,0) mg/m3",
      "NO: (0,63 – 25,0) mg/m3",
      "NO2: (0,19 – 19,0) mg/m3",
      "metoda elektrochemiczna",
    ],
    code: "PB-02 wyd. IV z dnia 15.04.2021r.",
  },
  {
    id: 5,
    title: "Stężenie ditlenku węgla",
    types: ["zakres: (500 – 91500) mg/m3", "metoda IR"],
    code: "PB-02 wyd. IV z dnia 15.04.2021r.",
  },
  {
    id: 6,
    title: "Mikroklimat zimny",
    types: [
      "temperatura powietrza: zakres (-20,0 ÷ 10,0) °C",
      "temperatura poczernionej kuli: zakres (-20,0 ÷ 10,0) °C",
      "wilgotność powietrza: zakres (19,9 ÷ 89,7) %",
      "prędkość powietrza: zakres (0,4 − 5,0) m/s",
      "metoda pomiarowa bezpośrednia",
      "wskaźnik IREQmin, wskaźnik IREQneutral, wskaźnik tWC (z obliczeń)",
    ],
    code: "PN-EN ISO 11079:2008",
  },
  {
    id: 7,
    title: "Mikroklimat umiarkowany",
    types: [
      "temperatura powietrza: zakres (10,0 ÷ 30,0) °C",
      "temperatura poczernionej kuli: zakres: (10,0 ÷ 35,0) °C",
      "wilgotność powietrza: zakres (19,9 ÷ 89,7) %",
      "prędkość powietrza: zakres (0,2 ÷ 1,0) m/s",
      "metoda pomiarowa bezpośrednia",
      "wskaźnik PMV, wskaźnik PPD (z obliczeń)",
    ],
    code: "PN-EN ISO 7730:2006 / PN-EN ISO 7730:2006/Ap2:2016-04",
  },
  {
    id: 8,
    title: "Mikroklimat gorący",
    types: [
      "temperatura powietrza: zakres (15,0 ÷ 60,0) °C",
      "temperatura poczernionej kuli: zakres (15,0 ÷ 60,0) °C",
      "temperatura wilgotna naturalna: zakres (15,0 ÷ 60,0) °C",
      "metoda pomiarowa bezpośrednia",
      "wskaźnik WBGT, wskaźnik WBGTeff (z obliczeń)",
    ],
    code: "PN-EN ISO 7243: 2018-01",
  },
  {
    id: 9,
    title: "Hałas",
    types: [
      "równoważny poziom dźwięku A",
      "maksymalny poziom dźwięku A: zakres: (24,0 ÷ 136,0) dB",
      "szczytowy poziom dźwięku C: zakres: (35,0 ÷ 140,0) dB",
      "metoda pomiarowa bezpośrednia",
      "poziom ekspozycji na hałas odniesiony do 8-godzinnego dobowego wymiaru czasu pracy (z obliczeń)",
      "poziom ekspozycji na hałas odniesiony do przeciętnego tygodniowego wymiaru czasu pracy (z obliczeń)",
    ],
    code: "PN-N-01307:1994 / PN-EN ISO 9612:2011 z wyłączeniem metody obejmującej strategię 3 – p. 11",
  },

  {
    id: 10,
    title: "Drgania mechaniczne o ogólnym działaniu na organizm człowieka",
    types: [
      "skuteczne ważone częstotliwościowo przyspieszenia drgań : zakres (0,03 - 700) m/s2",
      "metoda pomiarowa bezpośrednia",
      "ekspozycja dzienna, wyrażona w postaci równoważnego energetycznie dla 8 godzin działania skutecznego (z obliczeń), skorygowanego częstotliwościowo przyspieszenia drgań, dominującego wśród przyspieszeń drgań, wyznaczonych dla trzech składowych kierunkowych z uwzględnieniem właściwych współczynników (1,4awx; 1,4awy;  awz ) ",
      "ekspozycja trwająca 30 min i krócej, wyrażona w postaci skutecznego, ważonego częstotliwościowo przyspieszenia drgań, dominującego wśród przyspieszeń drgań, wyznaczonych dla trzech składowych kierunkowych z uwzględnieniem właściwych współczynników (1,4awx; 1,4awy;  awz ) (z obliczeń)",
    ],
    code: "PN-EN 14253+A1:2011",
  },
  {
    id: 12,
    title:
      "Drgania mechaniczne działające na organizm człowieka przez kończyny górne",
    types: [
      "Skuteczne ważone częstotliwościowo przyspieszenia drgań: zakres (0,03 - 1000) m/s2",
      "metoda pomiarowa bezpośrednia",
      "ekspozycja dzienna, wyrażona w postaci równoważnej energetycznie dla 8 godzin działania sumy wektorowej skutecznych, skorygowanych częstotliwościowo przyspieszeń drgań, wyznaczonych dla trzech składowych kierunkowych  (ahwx;  ahwy; ahwz )",
      "ekspozycja trwająca 30 min i krócej, wyrażona w postaci sumy wktorowej skutecznych, ważonych częstotliwościowo przyspieszeń drgań, wyznaczonych dla trzech składowych kierunkowych (ahwx; ahwy; ahwz ) (z obliczeń)",
    ],
    code: "PN-EN ISO 5349-1:2004 / PN-EN ISO 5349-2:2004 / PN-EN ISO 5349-2:2004/A1:2015-11",
  },
  {
    id: 13,
    title: "Oświetlenie elektryczne we wnętrzach",
    types: [
      "natężenie oświetlenia: zakres (19 - 5000) lx",
      "metoda pomiarowa bezpośrednia",
      "równomierność oświetlenia (z obliczeń)",
    ],
    code: "PN-83/E-04040/03 / PB-07 wydanie II z dnia 15.01.2013 r.",
  },
];

export default methodsAccredited;
