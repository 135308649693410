import React, { useEffect, useState } from 'react';
import { TimelineMax, Power1 } from 'gsap';
import styles from './Accredited.module.scss';

import HeaderPages from '../../components/HeaderPages/HeaderPages';
import Footer from '../../components/Footer/Footer';
import methodsAccredited from './TableData';

import image from '../../assets/images/analysis-03.jpg';
import logo from '../../assets/images/logo-pca.png';
import pdfFile from '../../assets/pdf/metody_akredytowane_new.pdf';


const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if(ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRef, visible]
}

const Accredited = () => {

  const [setRef, visible] = useOnScreen({threshold: 0.5})
  
  const [tlAccredited] = useState(new TimelineMax());
  let showTitle = null;
  let showText = null;
  let showText2 = null;
  let showImg = null;

    useEffect(() => {
      if(visible) {
         tlAccredited
          .to(showTitle, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut})
          .to(showText, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
          .to(showText2, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
          .to(showImg, 0.6, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
      }
      // eslint-disable-next-line
    }, [visible]);
    
  let [data, setData] = useState(methodsAccredited)
  let [value, setValue] = useState('')

  const onChange = (e) => {
    let searchTxt = e.target.value
    setValue(searchTxt)
    data = methodsAccredited
    setData(methodsAccredited)
    let searchFilter = data.filter(item => item.title.toUpperCase().includes(searchTxt.toUpperCase()))
    setData(searchFilter)
  }

  return (
    <div className={styles.wrapper}>
      <HeaderPages headertitle="metody Akredytowane" headerimage={image} />

      <section className={styles.accredited}>
        <div className={styles.boxTxt} ref={setRef}>
          <h4 className={styles.boxTxtH} ref={h4 => showTitle = h4}>Wykonujemy badania akredytowane.</h4>
          <div className={styles.akredytacja}>
            <div>
              <p className={styles.boxTxtP} ref={p => showText = p}>Spełniamy wiele wymogów Unii Europejskiej w zakresie prowadzonych badań. Akredytacja jest obiektywnym dowodem na to, iż nasze laboratorium działa zgodnie z najlepszą praktyką. Poniżej znajdą Państwo akredytowane metody badawcze wykonywane przez nasze laboratorium. 
              Można również wyszukać wpisując
              <input className={styles.inputTxt} placeholder="metodę badawczą" type="text" onChange={onChange} value={value}/><i className="fas fa-long-arrow-alt-right"/>

              </p>
              <p className={styles.boxTxtP} ref={p => showText2 = p}>
                <a className={styles.pdf} href={pdfFile} target='_blank' rel="noopener noreferrer">Wersja PDF</a> <sup><i className="far fa-arrow-alt-circle-down" style={{fontSize: '1rem', color: 'rgba(0,200,255,.5)'}}/></sup>
              </p>
            </div>
            <div className={styles.pcaDiv} ref={div => showImg = div}>
              <img className={styles.logoPca} src={logo} alt="akredytacja"/>
              <p className={styles.logoPcaTxt}>AB 1075</p>
            </div>
          </div>
        </div>
      
          <div className={styles.boxSurvey}>
            <div className={styles.topPanel}>
              <p className={styles.topPanelTxt}>Tytuł metody badawczej / pomiarowej</p>
              <p className={styles.topPanelTxt}>Nr normy / kod procedury badawczej</p>
            </div>
            <div className={styles.wrapperMethods}>
      
            {data.map(item => (
                <div className={styles.methods} key={item.id}>
                  <div className={styles.methodsLeft}>
                    <h4 className={styles.methodsLeftTitle}>{item.title}</h4>
                    {item.types.map(type => <p className={styles.methodsLeftTxt} key={type}><i className="fas fa-check"/> {type}</p>)}
                  </div>
                  <div className={styles.methodsRights}>
                    <p className={styles.methodsRightsTxt}>{item.code}</p>
                  </div>
                </div>
            ))}

            </div>
          </div>
        </section>
     
      <Footer />
    </div>
  )
}

export default Accredited