import React, { Fragment, useState, useEffect } from 'react';
import { TimelineMax, Power1 } from 'gsap';
import { NavLink } from 'react-router-dom';
import styles from './NavigationList.module.scss';

const NavigationList = () => {

  const [Text, setText] = useState(true);

  const [tlMenu] = useState(new TimelineMax( {paused: true} ));
  let showNav = null;
  let whiteDiv = null;
  let showUl = null;
  let spanBurger = null;
  let li01 = null;
  let li02 = null;
  let li03 = null;
  let li04 = null;
  let copy = null;

  const [tlBar] = useState(new TimelineMax());

  const openMenu = () => {
    if(tlMenu.paused()){
      tlMenu.play();
      setText(!Text);
    }else if(tlMenu.reversed()){
      tlMenu.play();
      setText(!Text);
    }else
      tlMenu.reverse();
      setText(!Text);
    }

    useEffect(() => {
      tlBar
      .to(spanBurger, 0.4, {opacity: 1, ease: Power1.easeOut}, 0.8)

      tlMenu
        .to(whiteDiv, 0.4, {y: 0, opacity: 1, ease: Power1.easeOut, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)'})
        .to(showNav, 0.4, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.4')
        .to(showNav, 0.2, {clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%);'}, '-=0.2')
        .to(spanBurger, 0.4, {boxShadow: 'inset -10px -10px 10px 0 rgba(254,254,254, 1),inset 10px 10px 10px 0 rgba(0,0,0, .03),-10px -10px 10px 0 rgba(254,254,254, 1),10px 10px 10px 0 rgba(0,0,0, .03)', ease: Power1.easeOut}, '-=0.2')
        .to(showUl, 0.4, {opacity: 1, ease: Power1.easeOut})
        .to(li04, 0.2, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.2')
        .to(li03, 0.2, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.1')
        .to(li02, 0.2, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.1')
        .to(li01, 0.2, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.1')
        .to(copy, 0.3, { opacity: 1, ease: Power1.easeOut}, '-=0.3')

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className={styles.menu}>
        <span onClick={openMenu} className={styles.burger} ref={span => spanBurger = span}>
          {Text ? (
          <span><i style={{fontSize: '.7rem', color: '#00C8FF'}} className="fas fa-bars"/> m<span className={styles.up}>e</span>nu</span>
          ) : (
          <span><i style={{fontSize: '.7rem', color: '#00C8FF'}} className="fas fa-times"/> za<span className={styles.down}>m</span>knij</span>
          )}</span>
      </div>

      <div className={styles.whiteDiv} ref={div => whiteDiv = div}>
        <nav className={styles.wrapper} ref={nav => showNav = nav}>
          <ul className={styles.wrapperUl} ref={ul => showUl = ul}>
            <li className={styles.navItem} onClick={openMenu} ref={li => li01 = li}>
              <NavLink exact
              className={styles.navItemLink} 
              activeClassName={styles.navItemLinkActive}
              to="/"><span className={styles.navSpan}>strona</span> Główna</NavLink>
            </li>
            <li className={styles.navItem} onClick={openMenu} ref={li => li02 = li}>
              <NavLink 
              className={styles.navItemLink}
              activeClassName={styles.navItemLinkActive} 
              to="/metody-akredytowane"><span className={styles.navSpan}>metody</span> Akredytowane</NavLink>
            </li>
            <li className={styles.navItem} onClick={openMenu} ref={li => li03 = li}>
              <NavLink 
              className={styles.navItemLink}
              activeClassName={styles.navItemLinkActive} 
              to="/metody-nieakredytowane"><span className={styles.navSpan}>metody</span> Nieakredytowane</NavLink>
            </li>
            <li className={styles.navItem} onClick={openMenu} ref={li => li04 = li}>
              <NavLink 
              className={styles.navItemLink}
              activeClassName={styles.navItemLinkActive} 
              to="/kontakt">kontakt</NavLink>
            </li>
          </ul>
          <div className={styles.copy} ref={div => copy = div}>
            <p className={styles.copyTxt}><i style={{color: '#00C8FF'}} className="far fa-copyright"/> 2020 LOŚP w Bydgoszczy</p>
            <p className={styles.copyTxt}><i style={{color: '#00C8FF'}} className="fas fa-code"/> BluSoft / j.j.</p>
          </div>
        </nav>
      </div>
    </Fragment>
  )
}

export default NavigationList