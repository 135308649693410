import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const iconStyle = {color: '#00C8FF', fontSize: '.9rem', marginLeft: '.4rem'}
const iconStyleTwo = {color: '#00C8FF', fontSize: '.9rem', marginRight: '.4rem'}
const iconStyleMobile = {color: '#00C8FF', fontSize: '.9rem', marginRight: '.6rem'}

function Footer() {

  const [mailIcon, setMailIcon] = useState(true);
  const onMouseEnter = () => setMailIcon(!mailIcon);
  const onMouseLeave = () => setMailIcon(!mailIcon);

  const [phoneIcon, setPhoneIcon] = useState(true);
  const onMouseEnter2 = () => setPhoneIcon(!phoneIcon);
  const onMouseLeave2 = () => setPhoneIcon(!phoneIcon);

  const [phoneIcon2, setPhoneIcon2] = useState(true);
  const onMouseEnter3 = () => setPhoneIcon2(!phoneIcon2);
  const onMouseLeave3 = () => setPhoneIcon2(!phoneIcon2);

  return (
    <footer className={styles.wrapper}>
      <div className={styles.contact}>
        <h4 className={styles.contactTitle}>Zapraszamy do kontaktu z nami</h4>
        <p><a className={styles.contactMail} href="mailto:laboratoriummaciej@wp.pl" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {mailIcon ? <i style={iconStyleTwo} className="far fa-envelope"/> : <i style={iconStyleTwo} className="fas fa-envelope"/>} laboratoriummaciej@wp.pl  
        </a></p>
        <p><a className={styles.contactMail} href="tel:605038804" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
          {phoneIcon ? <i style={iconStyleMobile} className="fas fa-mobile-alt"/> : <i style={iconStyleMobile} className="fas fa-mobile"/>} tel: 605-038-804
        </a></p>
        <p><a className={styles.contactMail} href="tel:525181505" onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}>
          {phoneIcon2 ? <i style={iconStyleTwo} className="fas fa-phone-square-alt"/> : <i style={iconStyleTwo} className="fas fa-phone-square"/>} tel: (52) 518 15 05
        </a></p>
      </div>
      <div className={styles.menuOffer}>
        <h4 className={styles.menuOfferTitle}>oraz do zapoznania się z nasza szczegółową ofertą</h4>
        <ul className={styles.menuOfferUl}>
          <li className={styles.menuOfferUlItem}>
          <Link 
            className={styles.menuOfferUlItemLink}
            to="/metody-akredytowane">
              <span className={styles.navSpan}>metody</span> Akredytowane <i style={iconStyle} className="fas fa-long-arrow-alt-right"/>
          </Link>
          </li>
          <li className={styles.menuOfferUlItem}>
          <Link 
            className={styles.menuOfferUlItemLink}
            to="/metody-nieakredytowane"><span className={styles.navSpan}>metody </span> Nieakredytowane <i style={iconStyle} className="fas fa-long-arrow-alt-right"/></Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
