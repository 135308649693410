import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax, Power1 } from 'gsap';

import styles from './HeaderPages.module.scss';

import defaultImg from '../../assets/images/methods-02.jpg';

const HeaderPages = ({ headertitle, headerimage }) => {

  const [tlHeader] = useState(new TimelineMax());
  let showHeader = null;
  let showTitle = null;
  let showImg  = null;

    useEffect(() => {
      tlHeader
        .to(showHeader, 0.3, {opacity: 1, ease: Power1.easeOut}, 1.7)
        .to(showTitle, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut})
        .to(showImg , 0.6, {scaleX: 1, ease: Power1.easeOut}, '-=0.1')
      // eslint-disable-next-line
    }, []);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header} ref={header => showHeader = header}>
        <div className={styles.boxtitle}>
          <h2 className={styles.boxtitleTitle} ref={h2 => showTitle = h2}>{headertitle}</h2>
        </div>
        <div className={styles.boximage}>
          <img className={styles.boximageImg} src={headerimage} alt="kontakt" ref={img => showImg = img}/>
        </div>
      </header>
    </div>
  )
}

HeaderPages.propTypes = {
  headertitle: PropTypes.string.isRequired,
  headerimage: PropTypes.string.isRequired
}

HeaderPages.defaultProps = {
  headertitle: 'metody Badań',
  headerimage: defaultImg
}

export default HeaderPages
