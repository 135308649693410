import React from 'react';
import styles from './ErrorPage.module.scss';

import errorImg from '../../assets/images/error-img.png';

const ErrorPage = () => {
  return ( 
    <div className={styles.wrapper}>
      <img className={styles.errorImg} src={errorImg} alt="error"/>
      <h2 className={styles.errorTitle}>404</h2>
      <p className={styles.errorText}>- podana strona nie istnieje -</p>
    </div>
   );
}
 
export default ErrorPage;