import React, { useEffect, useState } from "react";
import { TimelineMax, Power1 } from "gsap";
import Particles from "react-particles-js";
import styles from "./About.module.scss";

// import certificatePdf from "../../../assets/pdf/Certyfikat_Akredytacji.pdf";
import certificateImg from "../../../assets/images/certyfikat-akredytacji.jpg";
// import rangePdf from '../../../assets/pdf/zakres_akredytacji_2019.pdf';

import image1 from "../../../assets/images/laboratory-flasks-01.png";
import image2 from "../../../assets/images/laboratory-flasks-02.png";
import image3 from "../../../assets/images/laboratory-flasks-03.png";

const linkToAB =
  "https://www.pca.gov.pl/akredytowane-podmioty/akredytacje-aktywne/laboratoria-badawcze/AB 1075,plik.html";

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);
  return [setRef, visible];
};

const About = () => {
  const [setRef, visible] = useOnScreen({ threshold: 0.5 });

  const [tlAbout] = useState(new TimelineMax());
  let showTxt1 = null;
  let showTxt2 = null;
  let showTxt3 = null;

  useEffect(() => {
    if (visible) {
      tlAbout
        .to(showTxt1, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut })
        .to(showTxt2, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut }, "-=0.3")
        .to(showTxt3, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut }, "-=0.3");
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <section className={styles.wrapper} ref={setRef}>
      <Particles
        style={{ position: "absolute" }}
        params={{
          particles: {
            number: {
              value: 15,
            },
            size: {
              value: 3,
            },
            color: {
              value: "#00C8FF",
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <div className={styles.box}>
        <article className={styles.boxText}>
          <p className={styles.txt} ref={(p) => (showTxt1 = p)}>
            Firma działa nieprzerwanie od 2001 roku. W 2009 roku Laboratorium
            Ochrony Środowiska Pracy Maciej Jankowski uzyskało akredytację
            Polskiego Centrum Akredytacji.
          </p>
          <p className={styles.txt} ref={(p) => (showTxt2 = p)}>
            Certyfikat Akredytacji Laboratorium Badawczego nr{" "}
            <a
              className={styles.pdf}
              href={certificateImg}
              target="_blank"
              rel="noopener noreferrer"
            >
              AB 1075
            </a>{" "}
            <sup>
              <i
                className="far fa-arrow-alt-circle-down"
                style={{ fontSize: "1rem", color: "rgba(0,200,255,.5)" }}
              />
            </sup>{" "}
            potwierdza, że laboratorium spełnia wymagania normy PN-EN ISO/IEC
            17025:2005+Ap1+AC:2007 "Ogólne wymagania dotyczące kompetencji
            laboratoriów badawczych i wzorcujących".
          </p>
          <p className={styles.txt} ref={(p) => (showTxt3 = p)}>
            Certyfikat ten jest obiektywnym dowodem kompetencji technicznych
            laboratorium w{" "}
            <a
              className={styles.pdf}
              href={linkToAB}
              target="_blank"
              rel="noopener noreferrer"
            >
              zakresie
            </a>{" "}
            <sup>
              <i
                className="far fa-arrow-alt-circle-down"
                style={{ fontSize: "1rem", color: "rgba(0,200,255,.5)" }}
              />
            </sup>{" "}
            wykonywanych badań, zapewnia o działaniu zgodnym z najlepszą
            praktyką laboratoryjną, spełniającą wymagania międzynarodowych norm
            i wytycznych.
          </p>
        </article>
        <div className={styles.boxImage}>
          <img
            className={styles.boxImage1}
            src={image1}
            alt="urządzenia laboratoryjne"
          />
          <img
            className={styles.boxImage2}
            src={image2}
            alt="urządzenia laboratoryjne"
          />
          <img
            className={styles.boxImage3}
            src={image3}
            alt="urządzenia laboratoryjne"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
