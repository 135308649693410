import React, { useState, useEffect } from 'react';
import { TimelineMax, Power1 } from 'gsap';
import styles from './Header.module.scss';

import headerImg from '../../../assets/images/main-bg-07.jpg';

const Header = () => {

  const [tlHeader] = useState(new TimelineMax());
  let title1 = null;
  let title2 = null;
  let title3 = null;
  let title4 = null;
  let txt1 = null;
  let txt2 = null;
  let showImg = null;
  let showHeader = null;

    useEffect(() => {
      tlHeader
        .to(showHeader, 0.4, {opacity: 1, ease: Power1.easeOut})
        .to(title1, 0.5, {x: 0, opacity: 1, ease: Power1.easeOut}, 0.8)
        .to(title2, 0.5, {x: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
        .to(title3, 0.5, {x: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
        .to(title4, 0.5, {x: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
        .to(txt1, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.4')
        .to(txt2, 0.5, {y: 0, opacity: 1, ease: Power1.easeOut}, '-=0.3')
        .to(showImg, 1, {scale: 1.25, opacity: 1, ease: Power1.easeOut}, '-=0.8')
      // eslint-disable-next-line
    }, []);

  return (
    <div className={styles.wrapper}>
      <header className={styles.secondWrapper} ref={header => showHeader = header}>
        <div className={styles.title}>
          <h1 className={styles.titleMain} ref={h1 => title1 = h1}>Laboratorium</h1>
          <h1 className={styles.titleMain} ref={h1 => title2 = h1}>Ochrony</h1>
          <h1 className={styles.titleMain} ref={h1 => title3 = h1}>Środowiska</h1>
          <h1 className={styles.titleMain} ref={h1 => title4 = h1}>Pracy</h1>
          <div className={styles.contenerTxt}>
            <p className={styles.titleText} ref={p => txt1 = p} >Witamy na stronie akredytowanego laboratorium badawczego.</p>
            <p className={styles.titleText} ref={p => txt2 = p} >Podstawowym zadaniem naszego Laboratorium jest prowadzenie działalności badawczej w zakresie pomiarów czynników szkodliwych występujących w środowisku pracy.</p>
          </div>
        </div>
        <div className={styles.headerImg}>
          <img className={styles.bgImg} src={headerImg} alt="laboratorium" ref={img => showImg = img}/>
        </div>
      </header>
    </div>
  )
}

export default Header