import React, { useEffect } from 'react';
import styles from './Contact.module.scss';

// import HeaderContact from './HeaderContact/HeaderContact';
import Footer from '../../components/Footer/Footer';
import HeaderPages from '../../components/HeaderPages/HeaderPages';

import image from '../../assets/images/contact-01.jpg'
import zlecenie from '../../assets/pdf/Zlecenie_Druk.doc';

const icoStyl = {borderRadius: '5px', padding: '0.3rem', transition: '.3s'};
const icoStyl2 = {borderRadius: '5px', padding: '0.3rem', transition: '.3s', margin: '0 0.2rem'};

const Contact = () => {

  return (
    <div className={styles.wrapper}>
      <HeaderPages headertitle="kontakt" headerimage={image}/>

      <section className={styles.teleaddress}>
        <div className={styles.address}>
          <h4 className={styles.addressTitle}>Jeśli masz jakieś pytania</h4>
          <p className={styles.addressBox}>
            <a className={styles.addressLink} href="tel:525181505">
              <span className={styles.iconSpan}><i style={icoStyl} className="fas fa-phone-square-alt"/></span> (52) 518-15-05
            </a>
          </p>
          <p className={styles.addressBox}>
            <a className={styles.addressLink} href="tel:605038804">
              <span className={styles.iconSpan}><i style={icoStyl2} className="fas fa-mobile-alt"/></span> 605-038-804
            </a>
          </p>
          <p className={styles.addressBox}>
            <a className={styles.addressLink} href="mailto:laboratoriummaciej@wp.pl">
              <span className={styles.iconSpan}><i style={icoStyl} className="far fa-paper-plane"/></span> laboratoriummaciej@wp.pl
            </a>
          </p>
          <p className={styles.addressBox}>
            <a className={styles.addressLink} href={zlecenie} target='_blank' rel="noopener noreferrer">
              <span className={styles.iconSpan}><i style={icoStyl2} className="far fa-file-alt"/></span> formularz zlecenia
            </a>
          </p>
        </div>
        <div className={styles.address}>
          <h4 className={styles.addressTitle}>Znajdź nas tutaj</h4>
          <h5 className={styles.addressName}>Laboratorium Ochrony Środowiska Pracy Maciej Jankowski</h5>
          <p className={styles.addressTxt}>Zygmunta Augusta 11</p>
          <p className={styles.addressTxt}>85-082 Bydgoszcz</p>
          <p className={styles.addressTxtSrc}>Nasze laboratorium znajduje się na terenie zakładów PESA Bydgoszcz SA na drugim piętrze biurowca Odlewni Żeliwa Bydgoszcz sp. z o.o. (OŻB) - wjazd od strony ul. Witolda Pileckiego.</p>
        </div>
        <div className={styles.map}>
          <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2393.4701154871186!2d17.985648316181173!3d53.13765697993591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470313cca1a79afd%3A0x2d8eee2017430ce7!2sLaboratorium%20Ochrony%20%C5%9Arodowiska%20Pracy%20Maciej%20Jankowski!5e0!3m2!1spl!2spl!4v1580211816106!5m2!1spl!2spl" style={{border:0}} className={styles.mapGoogle}/>
        </div>
      </section>
      
      <Footer />
    </div>
  )
}

export default Contact
