import React, { useEffect, useState } from "react";
import { TimelineMax, Power1 } from "gsap";
import styles from "./NonAccredited.module.scss";

import HeaderPages from "../../components/HeaderPages/HeaderPages";
import NonAccreditedItem from "./NonAccreditedItem/NonAccreditedItem";
import Footer from "../../components/Footer/Footer";

import image from "../../assets/images/analysis-04.jpg";
import pdfFile from "../../assets/pdf/metody_nieakredytowane_new.pdf";

const methodsNonAccredited = [
  {
    id: 1,
    title: "Pomiar stężenia siarkowodoru metodą bezpośredniego pomiaru",
    code: "PB-02 wyd. IV z dnia 15.04.2021",
  },
  {
    id: 2,
    title: "Pomiar stężenia amoniaku metodą bezpośredniego pomiaru",
    code: "PB-02 wyd. IV z dnia 15.04.2021",
  },
  {
    id: 3,
    title: "Pomiar stężenia metanu metodą bezpośredniego pomiaru",
    code: "PB-02 wyd. IV z dnia 15.04.2021",
  },
  {
    id: 4,
    title: "Pomiar stężenia a ditlenku siarki metodą bezpośredniego pomiaru",
    code: "PB-02 wyd. IV z dnia 15.04.2021",
  },
  {
    id: 5,
    title: "Pomiar wydatku energetycznego metodą bezpośredniego pomiaru",
    code: "PB-03 wyd. II z dnia 25.09.2008",
  },
  {
    id: 6,
    title: "Pomiar strumienia objętości powietrza wentylacyjnego",
    code: "PB-04 wyd. II z dnia 25.09.2008",
  },
  {
    id: 7,
    title: "Pomiar natężenia oświetlenia awaryjnego",
    code: "PB-05 wyd. II z dnia 25.09.2008",
  },
  // {id: 8, title: 'Pomiar stężenia olejów mineralnych metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-06 wyd. II z dnia 25.09.2008'},
  // {id: 9, title: 'Pomiar stężenia kwasu azotowego metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-05 wyd. II z dnia 25.09.2008'},
  // {id: 10, title: 'Pomiar stężenia  kwasu solnego metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-06 wyd. II z dnia 25.09.2008'},
  // {id: 11, title: 'Pomiar stężenia  ozonu metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-06 wyd. II z dnia 25.09.2008'},
  //{id: 12, title: 'Pomiar stężenia kwasu solnego metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-06 wyd. II z dnia 25.09.2008'},
  //{id: 13, title: 'Pomiar stężenia ozonu metodą bezpośredniego pomiaru – rurki wskaźnikowe', code: 'PB-06 wyd. II z dnia 25.09.2008'},
];

const useOnScreen = (options) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);
  return [setRef, visible];
};

const NonAccredited = () => {
  const [setRef, visible] = useOnScreen({ threshold: 0.2 });

  const [tlNonAccredited] = useState(new TimelineMax());
  let showTitle = null;
  let showText = null;
  let showText2 = null;

  useEffect(() => {
    if (visible) {
      tlNonAccredited
        .to(showTitle, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut })
        .to(showText, 0.6, { y: 0, opacity: 1, ease: Power1.easeOut }, "-=0.3")
        .to(
          showText2,
          0.6,
          { y: 0, opacity: 1, ease: Power1.easeOut },
          "-=0.3"
        );
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <div className={styles.wrapper}>
      <HeaderPages headertitle="metody Nieakredytowane" headerimage={image} />
      <section className={styles.nonAccredited} ref={setRef}>
        <div className={styles.boxTxt}>
          <h4 className={styles.boxTxtH} ref={(h4) => (showTitle = h4)}>
            Oferujemy również badania nieakredytowane.
          </h4>
          <p className={styles.boxTxtP} ref={(p) => (showText = p)}>
            W zależności od Państwa potrzeb, czasem usługi akredytowane po
            prostu nie będą wymagane w danym kontekście. Są wymagane tylko
            wtedy, gdy do pomiarów zobowiązani będą Państwo na podstawie
            obowiązujących przepisów prawnych.
          </p>
          <p className={styles.boxTxtP} ref={(p) => (showText2 = p)}>
            <a
              className={styles.pdf}
              href={pdfFile}
              target="_blank"
              rel="noopener noreferrer"
            >
              Wersja PDF
            </a>{" "}
            <sup>
              <i
                className="far fa-arrow-alt-circle-down"
                style={{ fontSize: "1rem", color: "rgba(0,200,255,.5)" }}
              />
            </sup>
          </p>
        </div>
        <div className={styles.boxSurvey}>
          <div className={styles.topPanel}>
            <p className={styles.topPanelTxt}>
              Tytuł metody badawczej / pomiarowej
            </p>
            <p className={styles.topPanelTxt}>
              Nr normy / kod procedury badawczej
            </p>
          </div>
          {methodsNonAccredited.map((item) => (
            <NonAccreditedItem
              itemtitle={item.title}
              itemcode={item.code}
              key={item.id}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NonAccredited;
