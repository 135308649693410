import React, { Fragment } from 'react';
// import styles from './Home.module.scss';

import Header from './Header/Header';
import About from './About/About';
import Offer from './Offer/Offer';
import Solutions from './Solutions/Solutions';
import Footer from '../../components/Footer/Footer';


const Home = () => {
  return (
    <Fragment>
      <Header />
      <About />
      <Offer />
      <Solutions />
      <Footer />
    </Fragment>
  )
}

export default Home