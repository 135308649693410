import React from 'react';
import styles from './NonAccreditedItem.module.scss';

const NonAccreditedItem = ({ itemtitle, itemcode }) => {
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.leftTxt}>{itemtitle}</h4>
      <p className={styles.rightTxt}>{itemcode}</p>
    </div>
  )
}

export default NonAccreditedItem